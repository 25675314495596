import { MentionItem } from '@modules/mentions';
import { Delta } from 'quill/core';

export function fromDeltaToPlain(delta: Delta): string {
    let plainText = '';
    const ops = delta.ops;
    ops.forEach((element) => {
        if (element.insert && typeof element.insert !== 'string') {
            const mention = element.insert.mention as MentionItem;
            plainText += mention.denotationChar + mention.value;
        } else {
            plainText += element.insert;
        }
    });
    return plainText;
}
